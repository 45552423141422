import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { DataRequestModel, DataResponseGenericModel, DataResponseModel } from "src/app/models/generic-base/data-model";
import { OperationResultModel } from "src/app/models/shared/operation-result";

export interface CrudOperations<T, ID> {
  // index(): Observable<{}>;
  // listData(request: DataRequestModel): Observable<DataResponseModel>;
  // listGenericData(request: DataRequestModel): Observable<DataResponseGenericModel<T>>;
  // list(): Observable<T[]>;
  // get(id: ID): Observable<T>
  createOrEdit(model: T): Observable<OperationResultModel>;
  delete(id: ID): Observable<number>;
}

export abstract class GenericCrudService<T, ID> implements CrudOperations<T, ID>{

  constructor(
    protected http: HttpClient,
    protected baseUrl: string
  ) {
  }

  // index(): Observable<{}> {
  //   return this.http.get<{}>(this.baseUrl + "/Index");
  // }

  // listData(request: any): Observable<DataResponseModel> {
  //   return this.http.post<DataResponseModel>(this.baseUrl + "/ListData",
  //     request
  //   );
  // }

  // listGenericData(request: any): Observable<DataResponseGenericModel<T>> {
  //   return this.http.post<DataResponseGenericModel<T>>(this.baseUrl + "/ListData",
  //     request
  //   );
  // }

  // list(): Observable<T[]> {
  //   return this.http.get<T[]>(this.baseUrl + "/List");
  // }

  // get(id: ID): Observable<T> {
  //   return this.http.get<T>(this.baseUrl + "/Get?id=" + id);
  // }

  // create(model: T): Observable<number> {
  //   return this.http.post<number>(this.baseUrl + "/Create",
  //     model
  //   );
  // }

  // update(model: T): Observable<number> {
  //   return this.http.post<number>(this.baseUrl + "/Update",
  //     model
  //   );
  // }

  createOrEdit(model: T): Observable<OperationResultModel> {
    return this.http.post<OperationResultModel>(this.baseUrl + "/CreateOrEdit",
      model
    );
  }

  delete(id: ID): Observable<number> {
    return this.http.delete<number>(this.baseUrl + "/Delete?id=" + id);
  }
}
